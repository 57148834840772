<template>
  <div class="container">
    <template>
      <img :src="activeItem.img" alt="" class="banner" />
      <div class="inner" style="padding-bottom: 0">
        <Tabs :list="tags" :activeTag="activeTag" :showTri="true" @tabChoose="tabChoose" class="nav" />
      </div>
      <div class="box" :id="item.alias" v-for="(item,index) in list" :key="index" v-if="item">
        <div class="title-box">
          <div class="title">
            <div class="title__line"></div>
            <div>
              <div class="title__zh">{{item.name}}</div>
              <div class="title__en">{{item.engName}}</div>
            </div>
          </div>
          <div class="subtitle">
            <div class="subtitle__item" :class="{'subtitle--active': item.curIdx == subindex}" v-for="(subitem, subindex) in item.child" :key="subitem.id" @click="subtitleChange(subitem.id, index, subindex)">{{subitem.name}}</div>
          </div>
        </div>
        <div class="list">
          <div v-if="item.videoList.length > 0" class="list__item" v-for="(subitem, index) in item.videoList" :key="index" @click="toDetail(subitem)">
            <img :src="subitem.videoCover" alt="">
            <div class="list__item__desc">
              <div class="list__item__desc__title">{{subitem.title}} </div>
              <div class="list__item__desc__content">{{subitem.content}}</div>
              <div class="list__item__desc__tag" v-if="item.tag">{{item.tag}}</div>
              <div class="list__item__desc__tag" v-else>恒亿精选课程</div>
            </div>
          </div>
          <img v-if="!item.videoList.length" class="no-img" src="@/assets/hyschool/no-data.png" alt="">

          <!-- <div class="list__item" v-if="item.videoList && item.videoList.length % 3 === 2"></div> -->
        </div>
        <!-- 分页 -->
        <div class="paging" v-if="item.totalPage > 1">
          <img class="paging_" src="@/assets/hyschool/pre.png" alt="" @click="pre(item.curId, index)">
          <div class="paging__num" :class="{'paging__num--active' : num == item.curPage}" v-for="num in item.totalPage" :key="num" @click="getPageData(item.curId, index, num)">{{num}}</div>
          <img src="@/assets/hyschool/next.png" alt="" @click="next(item.curId, index)">
        </div>
      </div>
    </template>
    <SideBar />
  </div>
</template>

<script>
import Tabs from '@/components/Tab.vue'
import SideBar from '@/components/SideBar.vue'
import {
  essayChildListAPI,
  richChildListAPI,
  videoChidlListAPI,
  getEssayChildDetailAPI,
  videoDetailAPI,
} from '@/api'
import { getToken } from '@/utils'
import router from '@/router'

export default {
  name: 'hySchool',
  components: {
    SideBar,
    Tabs,
  },
  data() {
    return {
      isDetail: false,
      pageNum: 1,
      pageSize: 6,
      detail: {},
      data: [],
      curTabIndex: 0,
      curTabId: '',
      listScrollTop: '',
      list: [],
      tabList: [],
      tags: [],
      activeTag: '',
    }
  },
  computed: {
    activeItem() {
      this.pageNum = 1
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
  mounted() {
    this.getTab()
    window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
  },
  updated() {
    console.log('恒亿学院update')
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll) //离开页面需要移除这个监听的事件
  },
  methods: {
    toDetail(item) {
      console.log('视频详情页详情')
      const path = `/video/detail`
      sessionStorage.setItem('videoDetail', JSON.stringify(item))
      this.$router.push(path)
    },
    // 锚点跳转
    goAnchor(selector, index) {
      this.curTabId = selector
      console.log('锚点跳转')
      this.curTabIndex = index
      /*参数selector是id选择器（#anchor14）*/
      console.log(selector)
      const top = document.getElementById(selector).offsetTop
      const header = document.getElementById('header').clientHeight
      console.log(top, header)
      window.scrollTo({
        top: top - header,
        behavior: 'smooth',
      })
    },
    hideDetail() {
      console.log('this.listScrollTop', this.listScrollTop)
      this.isDetail = false
      window.scrollTo({
        top: this.listScrollTop,
        behavior: 'smooth',
      })
    },
    // share(url) {
    //   console.log(navigator.clipboard)
    //   if (navigator.clipboard && window.isSecureContext) {
    //     // navigator clipboard 向剪贴板写文本
    //     return navigator.clipboard.writeText(url)
    //   } else {
    //     // 创建text area
    //     let textArea = document.createElement('textarea')
    //     textArea.value = url
    //     // 使text area不在viewport，同时设置不可见
    //     textArea.style.position = 'absolute'
    //     textArea.style.opacity = 0
    //     textArea.style.left = '-999999px'
    //     textArea.style.top = '-999999px'
    //     document.body.appendChild(textArea)
    //     textArea.focus()
    //     textArea.select()
    //     return new Promise((res, rej) => {
    //       // 执行复制命令并移除文本框
    //       document.execCommand('copy') ? res() : rej()
    //       textArea.remove()
    //       this.$message('复制成功')
    //     })
    //   }
    // },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
      if (scrollTop < 300) {
        this.curTabIndex = 0
      }
    },
    getTab() {
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        console.log('childList', childList)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tabChoose(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        console.log('arr', arr)
        this.tags = arr
        this.activeTag = arr[0].name
        this.getList(arr)
      }
    },
    // 选择tab
    tabChoose(item) {
      console.log(item)
      this.activeTag = item.name
      var anchor = document.getElementById(item.alias)
      anchor.scrollIntoView({ behavior: 'smooth' })
    },
    getList(arr) {
      let list = []
      arr.forEach((item, index) => {
        let obj = {}
        obj.alias = item.alias
        obj.name = item.name
        obj.engName = item.engName
        obj.child = item.child
        obj.curIdx = 0
        obj.curId = item.child[0].id
        obj.curPage = 1
        videoChidlListAPI({
          id: item.child[0].id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        }).then((res) => {
          console.log('res', res)
          obj.videoList = res.list
          obj.total = res.total
          obj.totalPage = Math.ceil(res.total / this.pageSize)
          this.$set(this.list, index, obj)
        })
      })
    },
    subtitleChange(id, index, subindex) {
      console.log(id, index, subindex)
      this.pageNum = 1
      this.getDataById(id, index)
      this.$set(this.list[index], 'curIdx', subindex)
      this.$set(this.list[index], 'curId', id)
      this.$set(this.list[index], 'curPage', 1)
    },
    pre(id, index) {
      if (this.pageNum == 1) {
        return
      } else {
        this.pageNum = this.pageNum - 1
        this.$set(this.list[index], 'curId', id)
        this.$set(this.list[index], 'curPage', this.pageNum)
        this.getDataById(id, index)
      }
    },
    next(id, index) {
      if (this.pageNum < this.list[index].totalPage) {
        this.pageNum = this.pageNum + 1
        console.log(id, index)
        this.$set(this.list[index], 'curId', id)
        this.$set(this.list[index], 'curPage', this.pageNum)
        this.getDataById(id, index)
      }
    },
    getPageData(id, index, curNum) {
      this.pageNum = curNum
      this.$set(this.list[index], 'curId', id)
      this.$set(this.list[index], 'curPage', this.pageNum)
      this.getDataById(id, index)
    },
    getDataById(id, index) {
      videoChidlListAPI({
        id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log('res', res)
        // obj.videoList = res.list
        this.$set(this.list[index], 'videoList', res.list)
        this.$set(this.list[index], 'total', res.total)
        this.$set(
          this.list[index],
          'totalPage',
          Math.ceil(res.total / this.pageSize)
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background: #fff;
  margin-top: 0.92rem;
  overflow: hidden;
}
.banner {
  width: 100%;
}
.inner {
  width: 18rem;
  margin: 0 auto 0.78rem;
  box-sizing: border-box;
  padding: 0.51rem 0 1.03rem;
}
.box {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  margin: 0 auto 0;
  .title-box {
    width: 18rem;
    margin: 0 auto;
  }
  .title {
    margin: 0.15rem auto 0.53rem;
    display: flex;
    align-items: center;
    &__line {
      width: 0.03rem;
      height: 0.45rem;
      background: #ea3939;
      border-radius: 0rem 0rem 0rem 0rem;
      margin-right: 0.15rem;
    }
    &__zh {
      font-size: 0.32rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    &__en {
      font-size: 0.19rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 0.04rem;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 0.37rem;
    cursor: pointer;
    &__item {
      margin-right: 1.07rem;
      font-size: 0.24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    &--active {
      color: #ea3939;
    }
  }
  .list {
    width: 18rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    &__item {
      cursor: pointer;
      display: flex;
      margin-bottom: 0.61rem;
      img {
        // width: 4.92rem;
        // height: 2.93rem;
        width: 3.01rem;
        height: 1.8rem;
        border-radius: 0.05rem 0.05rem 0 0;
      }
      &__desc {
        width: 5.5rem;
        border-radius: 0 0 0.05rem 0.05rem;
        padding: 0 0.33rem;
        // box-shadow: 0.01rem 0.01rem 0.07rem 0.03rem rgba(124, 124, 124, 0.07);
        box-sizing: border-box;
        &__title {
          font-size: 0.21rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin: 0.13rem 0 0.2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__content {
          font-size: 0.19rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 超出几行省略 */
          overflow: hidden;
        }
        &__tag {
          display: inline-block;
          text-align: center;
          box-sizing: border-box;
          background: linear-gradient(90deg, #d1e6f8 0%, #d1e6f8 100%);
          padding: 0.04rem 0.18rem;
          border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
          opacity: 1;
          color: #ea3939;
          font-size: 0.13rem;
          font-family: PingFang SC-Regular, PingFang SC;
          margin-top: 0.31rem;
        }
      }
    }
  }
}
.paging {
  width: 18rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  img {
    width: 0.21rem;
    height: 0.21rem;
  }
  &__num {
    margin: 0 0.17rem;
    font-size: 0.19rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    &--active {
      color: #ea3939;
    }
  }
}
.no-img {
  width: 3.01rem;
  height: 1.8rem;
  border-radius: 0.05rem 0.05rem 0 0;
  margin-bottom: 0.61rem;
}
</style>