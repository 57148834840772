<template>
  <div class="container">
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="inner" style="padding-bottom: 0">
      <Tabs :list="tags" :activeTag="activeTag" @tabChoose="tabChoose" />
    </div>
    <div class="box" v-for="item in list" :key="item.id" v-if="!isDetail">
      <div class="list" @click="toDetail(item)">
        <div class="item">
          <!-- <img :src="item.img" alt=""> -->
          <div class="item__center">
            <div class="item__center__title">{{item.title}}</div>
            <!-- <div class="item__center__desc">{{item.introduction}}</div> -->
          </div>
          <div class="item__right">{{item.createTime | dateFormat}}</div>
        </div>
      </div>
    </div>
    <div class="no-data" v-if="list.length == 0">
      暂无数据
    </div>
    <div class="pagination" v-show="total > 0">
      <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <SideBar />
  </div>
</template>

<script>
import { getArticleListAPI, getArticleDetailAPI } from '@/api'
import SideBar from '@/components/SideBar.vue'
import Tabs from '@/components/Tab.vue'
export default {
  components: {
    SideBar,
    Tabs,
  },
  data() {
    return {
      isDetail: false,
      detail: {},
      pageNum: 1,
      pageSize: 10,
      first: {},
      list: [],
      scrollTop: '',
      listScrollTop: '',
      tags: [],
      activeTag: '',
      id: '', // 当前选中的菜单id
      total: 0,
    }
  },
  computed: {
    activeItem() {
      this.pageNum = 1
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      console.log('$store.state.menus改变')
      this.getTab()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
  filters: {
    dateFormat(val) {
      let arr = val.split(' ')
      return arr[0]
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getTab()
    window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll) //离开页面需要移除这个监听的事件
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    },
    toDetail(item) {
      console.log('跳转到慈善详情')
      const path = `/charity/detail`
      sessionStorage.setItem('charityDetail', JSON.stringify(item))
      this.$router.push(path)
      // this.isDetail = true
      // this.detail = item
      // this.listScrollTop = this.scrollTop
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })
    },
    hideDetail() {
      console.log('this.scrollTop', this.scrollTop)
      this.isDetail = false
      window.scrollTo({
        top: this.listScrollTop,
        behavior: 'smooth',
      })
    },
    async getList(id) {
      // const { id } = this.activeItem
      // const id = 26
      const { list, total } = await getArticleListAPI({
        id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
      this.total = total
      this.list = list
      // if (this.pageNum > 1) {
      //   this.list = [...this.list, ...list]
      // } else {

      //   this.list = list
      // }
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList(this.id)
    },
    // 获取tab
    getTab() {
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tabChoose(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        this.tags = arr
        this.activeTag = arr[0].name
        this.id = arr[0].id
        this.getList(arr[0].id)
      }
    },
    // 选择tab
    tabChoose(item) {
      console.log('item*****', item)
      this.activeTag = item.name
      // var anchor = document.getElementById(item.alias)
      // anchor.scrollIntoView({ behavior: 'smooth' })
      this.pageNum = 1
      this.id = item.id
      this.getList(item.id)
    },
    // loadMore() {
    //   this.pageNum++
    //   this.getList()
    // },
    // setIsDetail(isDetail) {
    //   this.isDetail = isDetail
    // },

    // async quickChange(id, menuId) {
    //   const data = await getArticleDetailAPI({
    //     id,
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum,
    //     menuId,
    //   })
    //   this.detail = data
    // },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ea3939;
}

.container {
  width: 100%;
  background: #fff;
  margin-top: 0.92rem;
  overflow: hidden;
}
.banner {
  width: 100%;
}
.inner {
  max-width: 1440px;
  margin: 0 auto 20px;
  box-sizing: border-box;
  padding: 0.7rem 1.1rem 1.03rem;
}
.list {
  width: 18rem;
  margin: 0 auto 0;
  padding: 0.2rem 0 0;
  transition: margin-left 1s linear 0;
  transition: width 1s linear 0;
  .item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px dotted #ccc;
    // img {
    //   width: 4.15rem;
    //   height: 2.48rem;
    //   // margin-right: 0.64rem;
    // }
    &__center {
      width: 14rem;
      &__title {
        font-size: 0.24rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.28rem;
      }
      // &__desc {
      //   font-size: 0.19rem;
      //   font-family: PingFang SC-Regular, PingFang SC;
      //   font-weight: 400;
      //   color: #666666;
      //   line-height: 0.28rem;
      //   margin-top: 0.51rem;
      // }
    }
    &__right {
      font-size: 0.24rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 500;
      color: #333333;
      // line-height: 0.47rem;
      letter-spacing: 1px;
    }
  }
}
// .box:hover {
//   background: #f9fbfc;
//   .list {
//     padding-left: 0.3rem;
//     box-sizing: border-box;
//     width: 18.3rem;
//     // margin-left: 4.2rem;
//   }
// }
// .box:nth-child(2n + 1) {
//   background: #f9fbfc;
// }
.pagination {
  display: flex;
  justify-content: right;
  margin: 20px 0 0.78rem;
}
.no-data {
  text-align: center;
  font-size: 16px;
  margin: 100px 0;
}
</style>