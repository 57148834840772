<template>
  <div class="container">
    <img src="../../assets/loginBg.png" alt="" class="img" />
    <div class="info">
      <div class="title">会员登录</div>
      <div class="inputWrap">
        <!-- <img src="../../assets/avatar.png" alt="" class="icon" /> -->
        <input type="text" class="input" v-model="name" placeholder="请输入会员名称" />
      </div>
      <div class="inputWrap">
        <!-- <img src="../../assets/pwd.png" alt="" class="icon" /> -->
        <input type="password" class="input" v-model="password" placeholder="请输入登录密码" />
      </div>
      <div class="avoidLogin">
        <el-checkbox v-model="checked">免登录</el-checkbox>
      </div>
      <button class="btn" @click="login">登录</button>
    </div>
  </div>
</template>

<script>
import { loginAPI } from '@/api'
import router from '@/router'
export default {
  data() {
    return {
      checked: true,
      name: '',
      password: '',
    }
  },
  methods: {
    async login() {
      const { name, password, checked } = this
      if (!name || !password) {
        this.$message.error('请确认填写内容正确！')
      }
      const data = await loginAPI({ name, password })
      console.log(data, checked)
      if (data) {
        sessionStorage.setItem('USER_INFO', JSON.stringify(data))
        this.$message.success('登录成功')
        const path = JSON.parse(sessionStorage.getItem('ACTIVEITEM')).path
        router.replace('/hySchool')
        // router.push(path)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
}
.img {
  width: 100%;
  height: 11.87rem;
  vertical-align: middle;
}
.info {
  width: 5.36rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.13rem 0.13rem 0.13rem 0.13rem;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  right: 2.17rem;
  top: 3.3rem;
  box-shadow: 1px 6px 10px #a3a2a2;
  padding: 0.35rem 0 0.57rem 0;
}
.title {
  font-size: 0.24rem;
  color: #333;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  margin-bottom: 0.31rem;
}
.inputWrap {
  display: flex;
  align-items: center;
  background: #efeff0;
  margin-bottom: 0.26rem;
  width: 4.68rem;
  margin: 0.51rem auto 0;
  .icon {
    box-sizing: border-box;
    width: 0.26rem;
    height: 0.26rem;
    margin: 0.19rem;
  }
  .input {
    font-size: 0.18rem;
    color: #999999;
    width: 4.68rem;
    line-height: 0.63rem;
    padding-left: 0.23rem;
    outline: none;
    border: none;
    background: #efeff0;
  }
}
.avoidLogin {
  width: 4.68rem;
  margin: 0.25rem auto 0;
  align-self: flex-start;
  margin-bottom: 0.7rem;
}
.btn {
  cursor: pointer;
  width: 2.55rem;
  height: 0.63rem;
  background: #ea3939;
  color: #fff;
  border: none;
  font-size: 0.24rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}
</style>